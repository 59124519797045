import { PayoutPreview, StripePaymentStatus } from "@withjuly/fabric";
import { Bank, ClockClockwise, Info, Warning } from "@withjuly/julycons/bold";
import { CurrencyDollar } from "@withjuly/julycons/regular";
import { Loader } from "@withjuly/solis";
import {
	Button,
	Modal,
	ScrollArea,
	Table,
	Tag,
	TagProps,
	Tooltip,
	useToast,
} from "@withjuly/solisv2";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { JSXElementConstructor, ReactElement, useMemo, useState } from "react";
import { trpc } from "~/components/Utility/trpc";
import { AgencyLayout } from "~/layout/AgencyLayout";
import { AgencyAppLayout } from "~/layout/AgencyAppLayout";
import { NextPageWithLayout } from "~/utils/next";
import Head from "next/head";

const Payouts: NextPageWithLayout = () => {
	const { data: payouts, isLoading } =
		trpc.agency.payments.getPayoutPreviews.useQuery();

	const [openedUuid, setOpenedUuid] = useState<string | undefined>(
		() => undefined,
	);

	const tableBody = useMemo(() => {
		if (isLoading) {
			return (
				<div className="flex h-full w-full items-center justify-center">
					<Loader />
				</div>
			);
		} else if (payouts?.length === 0) {
			return (
				<div className="flex h-full w-full items-center justify-center">
					<div className="flex flex-col items-center justify-center gap-4">
						<div className="rounded-solis-md bg-sky-alpha-4 flex h-10 w-10 items-center justify-center">
							<CurrencyDollar className="text-brand h-6 w-6" />
						</div>
						<div className="flex flex-col gap-1 text-center">
							<p className="font-repro text-header-lg">No payouts yet</p>
							<p className="font-repro text-paragraph-sm text-text-tertiary">
								When money is transferred out of July, it’ll be logged here.
							</p>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<ScrollArea className="h-full px-4">
					{(payouts ?? []).map((payout) => {
						return (
							<PayoutRow
								key={payout.uuid}
								setOpenedUuid={setOpenedUuid}
								{...payout}
							/>
						);
					})}
				</ScrollArea>
			);
		}
	}, [isLoading, payouts]);

	return (
		<>
			<Head>
				<title>Payouts • July</title>
			</Head>

			<div className="relative flex h-full max-h-screen w-full flex-col gap-8 p-8 pb-12">
				<div>
					<h1 className="text-header-2xl font-repro">Payouts</h1>
				</div>

				<div className="rounded-solis-xl border-stroke-secondary bg-surface-secondary flex h-full w-full flex-col overflow-auto border pt-2">
					<div className="border-b-stroke-secondary border-b px-4 uppercase">
						<Table.Row className="h-auto pb-2">
							<Table.Cell className="w-full max-w-[160px]" variant="th">
								Date
							</Table.Cell>
							<Table.Cell variant="th" className="max-w-[192px]">
								To
							</Table.Cell>
							<Table.Cell variant="th">For payment from</Table.Cell>
							<Table.Cell variant="th" align="right">
								Amount
							</Table.Cell>
							<Table.Cell
								variant="th"
								align="right"
								className="w-full max-w-[128px]"
							>
								Status
							</Table.Cell>
						</Table.Row>
					</div>
					{tableBody}
				</div>

				<Disclosure />
			</div>
			<PayoutModal
				uuid={openedUuid}
				setIsOpen={() => setOpenedUuid(() => undefined)}
				isOpen={openedUuid !== undefined}
			/>
		</>
	);
};

Payouts.getLayout = (page: ReactElement) => {
	return (
		<AgencyAppLayout>
			<AgencyLayout>{page}</AgencyLayout>
		</AgencyAppLayout>
	);
};

export default Payouts;

type PayoutRowProps = PayoutPreview & { setOpenedUuid: (uuid: string) => void };

const PayoutRow: React.FC<PayoutRowProps> = ({
	uuid,
	paidOutAt,
	to,
	forPayment,
	amount,
	status,
	setOpenedUuid,
}) => {
	const statusCell = useMemo(() => {
		const [color, text, icon] = getStatusAndText(status);

		return (
			<Table.Cell
				variant="tag"
				text={text}
				color={color}
				size="md"
				align="right"
				leftIcon={icon}
				className="w-full max-w-[128px]"
			/>
		);
	}, [status]);

	return (
		<Table.Row onClick={() => setOpenedUuid(uuid)}>
			<Table.Cell className="w-full max-w-[160px]" variant="compact">
				{paidOutAt.toLocaleDateString("en-US", {
					day: "2-digit",
					month: "short",
					year: "numeric",
				})}
			</Table.Cell>
			<Table.Cell className="max-w-[192px]">{to}</Table.Cell>
			<Table.Cell>{forPayment ? forPayment : "-"}</Table.Cell>
			<Table.Cell align="right">
				{amount.toLocaleString("en-US", {
					style: "currency",
					currency: "USD",
				})}
			</Table.Cell>
			{statusCell}
		</Table.Row>
	);
};

interface PayoutModalProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	uuid?: string;
}

const PayoutModal: React.FC<PayoutModalProps> = ({
	uuid,
	isOpen,
	setIsOpen,
}) => {
	const getReceipt =
		trpc.agency.payments.getOutboundPaymentReceipt.useMutation();
	const { data: payout, isLoading } =
		trpc.agency.payments.getPayoutDetails.useQuery(uuid ?? "", {
			enabled: uuid !== undefined,
		});
	const retryPayout = trpc.agency.payments.retryPayout.useMutation();
	const utils = trpc.useContext();

	const { toast } = useToast();

	const onRetryPayout = () => {
		retryPayout.mutate(
			{ payoutUuid: uuid ?? "" },
			{
				onSuccess: () => {
					toast({
						title: "Payout retried",
						variant: "success",
					});
					utils.agency.payments.getPayoutDetails.invalidate();
					utils.agency.payments.getPayoutPreviews.invalidate();
				},
				onError: () => {
					toast({
						title: "Something went wrong",
						variant: "error",
					});
					utils.agency.payments.getPayoutDetails.invalidate();
				},
			},
		);
	};

	const sections = useMemo(() => {
		if (!payout) {
			return [];
		}

		const amount = (
			<div className="flex w-full items-center justify-between px-2">
				<p className="text-paragraph-sm font-repro">Amount sent</p>
				<p className="text-paragraph-sm font-repro">
					{payout.amount.toLocaleString("en-US", {
						style: "currency",
						currency: "USD",
					})}
				</p>
			</div>
		);

		const julyFee = payout.fee ? (
			<div className="flex w-full items-center justify-between px-2">
				<div className="flex items-center gap-1">
					<p className="text-paragraph-sm font-repro">July Fee </p>
					<Tooltip
						tooltip={
							"July takes a 1% fee, capped at $25.00, from each split's total value including talent payouts."
						}
					/>
				</div>
				<p className="text-paragraph-sm font-repro">
					{payout.fee.toLocaleString("en-US", {
						style: "currency",
						currency: "USD",
					})}
				</p>
			</div>
		) : null;

		const [color, text, icon] = getStatusAndText(payout.status);
		const status = (
			<div className="flex w-full items-center justify-between px-2">
				<p className="text-paragraph-sm font-repro">Status</p>
				<Tag text={text} color={color} leftIcon={icon} />
			</div>
		);

		const account = (
			<div className="flex w-full items-center justify-between px-2">
				<p className="text-paragraph-sm font-repro">Account</p>
				<p className="text-paragraph-sm font-repro">
					{payout.accountLastFour && payout.accountType
						? `${payout.accountType === "company" ? "Business" : "Individual"} ending in ${payout.accountLastFour}`
						: "-"}
				</p>
			</div>
		);

		const datePosted =
			payout.status === StripePaymentStatus.enum.posted && payout.postedAt ? (
				<div className="flex w-full items-center justify-between px-2">
					<p className="text-paragraph-sm font-repro">Date posted</p>
					<p className="text-paragraph-sm font-repro">
						{payout.postedAt.toLocaleDateString("en-US", {
							day: "2-digit",
							month: "short",
							year: "numeric",
						})}
					</p>
				</div>
			) : null;

		return [amount, julyFee, status, account, datePosted]
			.filter((section) => section !== null)
			.map((section) => {
				return (
					<>
						{section}
						<div className="bg-stroke-tertiary h-px w-full last:hidden"></div>
					</>
				);
			});
	}, [payout]);

	return (
		<Modal.Root isOpen={isOpen} size="md" setIsOpen={setIsOpen}>
			<Modal.Header title="Payout details" />
			<Modal.Body className="p-0">
				{!payout || isLoading ? (
					<div className="flex h-[393px] w-full items-center justify-center">
						<Loader />
					</div>
				) : (
					<div className="flex w-full flex-col gap-12 p-8 pb-12">
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full items-center justify-between px-2">
								<p className="text-paragraph-sm font-repro">Paid to:</p>
								<p className="text-paragraph-sm font-repro">{payout.to}</p>
							</div>
							<div className="bg-stroke-tertiary h-px w-full">
								{/* DIVIDER */}
							</div>
							<div className="flex w-full items-center justify-between px-2">
								<p className="text-paragraph-sm font-repro">
									For payment from:
								</p>
								<p className="text-paragraph-sm font-repro">
									{payout.forPayout ?? "-"}
								</p>
							</div>
						</div>

						<div className="flex w-full flex-col gap-3">{sections}</div>
						{payout.status === "failed" || payout.status === "canceled" ? (
							<Button
								onClick={onRetryPayout}
								variant="danger"
								disabled={retryPayout.isLoading}
								isLoading={retryPayout.isLoading}
							>
								Retry
							</Button>
						) : (
							<Button
								variant="secondary"
								onClick={() => {
									if (!uuid) {
										return;
									}

									getReceipt.mutate(uuid, {
										onSuccess: (data) => {
											const anchor = document.createElement("a");
											anchor.href = data;
											anchor.target = "_blank";
											anchor.click();
										},
									});
								}}
							>
								View receipt
							</Button>
						)}
					</div>
				)}
			</Modal.Body>
		</Modal.Root>
	);
};

const Disclosure: React.FC = () => {
	const isFiveThird = useFeatureFlagEnabled("five-three-bank");

	return (
		<div className="text-text-placeholder font-repro absolute bottom-0 left-0 flex w-full items-center justify-center gap-1 py-4">
			<Info className="h-3 w-3" />
			<p className="text-paragraph-xs font-repro">
				July Technologies Inc. partners with{" "}
				<a href="https://stripe.com/" target="_blank" className="underline">
					Stripe Payments Company
				</a>{" "}
				for money transmission services and account services with funds held at
				{isFiveThird ? " Fifth Third Bank" : " Evolve Bank & Trust"}, Member
				FDIC.
			</p>
		</div>
	);
};

// UTILITY

export const getStatusAndText = (
	status: StripePaymentStatus,
): [
	TagProps["color"],
	string,
	JSXElementConstructor<{ className?: string }>,
] => {
	if (status === StripePaymentStatus.enum.canceled) {
		return ["red", "Canceled", Warning];
	} else if (status === StripePaymentStatus.enum.posted) {
		return ["green", "Posted", Bank];
	} else if (status === StripePaymentStatus.enum.processing) {
		return ["brand", "Processing", ClockClockwise];
	} else if (status === StripePaymentStatus.enum.returned) {
		return ["red", "Returned", Warning];
	} else {
		return ["red", "Failed", Warning];
	}
};
